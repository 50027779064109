body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hero {
  height: 70vh;
  position: relative;
  z-index: 0;
}
.hero img {
  border-radius: 25px;
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
}
.angle {
  width: 100%;
  height: 400px;
  position: relative;
  z-index: 0;
}
.rotateOnHover:hover {
  animation: spin 2s linear;
}
@keyframes spin {
  100% {
    transform: rotate(5deg);
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
